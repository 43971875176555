import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
function NouveauLabo() {

  const [nom, setNom] = useState('');
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (!nom) {
      toast.error('Veuillez saisir un nom de laboratoire.');
      return;
    }

    setLoading(true);

    try {
      await axios.post('http://localhost:4000/labo/new', { nom });

      setNom('');
      toast.success('Laboratoire enregistré avec succès.');
    } catch (error) {
      toast.warn('Une erreur s\'est produite lors de l\'enregistrement du laboratoire.');
    } finally {
      setLoading(false);
    }
  };;


  return (
    <>
      <div class="container-fluid">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title fw-semibold mb-4">Nouveau Laboratoire</h5>
              <div class="card">
                <div class="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="Nom" className="form-label">
                        Nom
                      </label>
                      <input
                        type="text"
                        placeholder="Nom du Laboratoire"
                        className="form-control"
                        id="Nom"
                        value={nom}
                        onChange={(e) => setNom(e.target.value)}
                      />
                    </div>
                    <button type="submit" className="btn btn-primary float-end" disabled={loading}>
                      {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NouveauLabo;
