import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
function Axe_Recherche() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Récupérer les données de l'API
    axios
      .get("http://localhost:4000/organisation/getAll")
      .then((response) => {
        setData(response.data);
        console.log(response.data);
        setIsLoading(false); // Définir isLoading sur false lorsque les données sont chargées
      })
      .catch((error) => {
        console.error(error.message);
        setIsLoading(false); // Définir isLoading sur false en cas d'erreur
      });
  }, []);

  const handleDeleteAxe = (axeId) => {
    Swal.fire({
      title: "Etes-Vous Sur ?",
      text: "Supprimer un axe de recherche ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      reverseButtons: true,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`http://localhost:4000/axe/delete/${axeId}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success("axes supprimé avec succès !");
              setData((prevData) =>
                prevData.filter(
                  (item) => !item.axerecherches.some((axe) => axe.id === axeId)
                )
              );
            } else {
              console.error("Erreur lors de la suppression");
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    });
  };
  const handleDeleteChercheur = (chercheurId) => {
    Swal.fire({
      title: "Etes-Vous Sur ?",
      text: "Retirer un chercheur dans une équipe ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      reverseButtons: true,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `http://localhost:4000/organisation/deletemember/${chercheurId}`
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success("Chercheur retiré de l'équipe avec succès !");

              // Mettez à jour l'état data en supprimant le chercheur de l'équipe
              setData((prevData) => {
                return prevData.map((item) => {
                  return {
                    ...item,
                    organisations: item.organisations.filter(
                      (org) => org.chercheur.id !== chercheurId
                    ),
                  };
                });
              });
            } else {
              console.error("Erreur lors de la suppression");
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="container pb-3">
          <div className="text-center">
            <h5
              className="text-primary text-uppercase mb-3"
              style={{ letterSpacing: "5px" }}
            >
              Nos Recherches
            </h5>
            <Link
              to={`/Axe_de_Recherche/Nouveau`}
              className="btn btn-primary mb-3 mx-5"
            >
              Nouveau Axes de Recherche
            </Link>
            <Link
              to={`/Ajouter_Chercheur`}
              className="btn btn-primary mb-3 mx-5"
            >
              Ajouter Chercheur
            </Link>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 d-flex">
              <div className="card w-100">
                <div className="card-body p-4">
                  <h5 className="card-title fw-semibold mb-4">Recherches</h5>
                  {isLoading ? ( // Afficher l'indicateur de chargement si isLoading est vrai
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Chargement en cours...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th className="col-lg-4">Equipes</th>
                            <th className="col-lg-4">Chercheurs</th>
                            <th className="col-lg-4">Axes de recherches</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item) => (
                            <tr key={item.id}>
                              <td>{item.titre}</td>
                              <td>
                                {item.organisations.map((org) => (
                                  <div key={org.id} className="mb-1">
                                    {org.chercheur.grade} - {org.chercheur.nom}
                                    <div>
                                      <button
                                        onClick={() =>
                                          handleDeleteChercheur(
                                            org.chercheur.id
                                          )
                                        }
                                        className="btn btn-sm btn-outline-primary mx-1"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </td>
                              <td>
                                {item.axerecherches.map((axe) => (
                                  <div key={axe.id} className="mb-1">
                                    {axe.axes}
                                    <div>
                                      <Link
                                        to={`/Axe_de_Recherche/Editer/${axe.id}`}
                                        className="btn btn-sm btn-outline-primary mx-1"
                                      >
                                        <i className="fas fa-pencil-alt"></i>
                                      </Link>
                                      <button
                                        onClick={() => handleDeleteAxe(axe.id)}
                                        className="btn btn-sm btn-outline-primary mx-1"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Axe_Recherche;
