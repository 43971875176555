import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import { toast } from "react-toastify";

function NouveauAxe_Recherche() {
  const [selectedEquipe, setSelectedEquipe] = useState(null);
  const [EquipeList, setEquipeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [axes, setAxes] = useState([{ axe: "" }]); // Un champ d'axe par défaut

  useEffect(() => {
    // Récupérer la liste des Equiperatoires depuis l'API
    axios
      .get("http://localhost:4000/equipe/getAll")
      .then((response) => {
        setEquipeList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setIsLoading(false);
      });
  }, []);

  const validateForm = () => {
    if (!selectedEquipe) {
      toast.error("Veuillez sélectionner un Equipe.");
      return false;
    }

    if (axes.length === 0 || axes.some((item) => item.axe.trim() === "")) {
      toast.error("Veuillez ajouter  un axe non completé.");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Valider les champs
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    // Envoyer les données du formulaire à l'API pour enregistrement
    const formData = {
      equipeId: selectedEquipe.value,
      axes: axes.map((item) => item.axe).filter((axe) => axe.trim() !== ""),
    };

    setLoading(false);

    axios
      .post("http://localhost:4000/axe/new", formData)
      .then((response) => {
        toast.success("Axe de recherche enregistré avec succès !");
        setSelectedEquipe(null);
        setAxes([{ axe: "" }]);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error.message);
        setLoading(false);
        if (error.response) {
          toast.error(error.response.data);
        }
      });
  };

  const addAxe = () => {
    setAxes([...axes, { axe: "" }]);
  };

  const updateAxe = (index, value) => {
    const updatedAxes = axes.map((item, i) =>
      i === index ? { axe: value } : item
    );
    setAxes(updatedAxes);
  };

  const removeAxe = (index) => {
    if (index === 0) {
      // Ne supprimez pas le premier champ, car il est non modifiable
      return;
    }
    const updatedAxes = [...axes];
    updatedAxes.splice(index, 1);
    setAxes(updatedAxes);
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement en cours...</span>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title fw-semibold mb-4">Nouvel Axe de Recherche</h5>
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="EquipeId" className="form-label">
                        Equipe
                      </label>
                      <ReactSelect
                        id="EquipeId"
                        options={EquipeList.map((Equipe) => ({
                          value: Equipe.id,
                          label: Equipe.titre,
                        }))}
                        value={selectedEquipe}
                        onChange={(selectedOption) => setSelectedEquipe(selectedOption)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Axes de Recherche</label>
                      {axes.map((item, index) => (
                        <div key={index} className="input-group mb-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Axe de Recherche"
                            value={item.axe}
                            onChange={(e) => updateAxe(index, e.target.value)}

                          />
                          {index === 0 ? (
                            ""
                          ) : (
                            <span className="btn btn-danger" onClick={() => removeAxe(index)}>
                              <i className="fas fa-trash"></i>
                            </span>
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addAxe}
                      >
                        Ajouter un Axe
                      </button>
                    </div>
                    <button type="submit" className="btn btn-primary float-end" disabled={loading}>
                      {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NouveauAxe_Recherche;
