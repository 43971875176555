import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useParams,useNavigate } from 'react-router-dom';

const categories = [
    { value: 'video', label: 'video' },
    { value: 'text', label: 'photo' },
    { value: 'audio', label: 'audio' },
];

function NewPostContent() {
    const history=useNavigate()
    const { lang, id } = useParams()
    const [title, settitle] = useState('');
    const [categorie, setCategorie] = useState(null);
    const [description, setdescription] = useState('');
    const [Audio, setAudio] = useState('');
    const [video_url, setvideo_url] = useState('');
    const [photo, setPhoto] = useState(null);
    const [preview, setPreview] = useState(null);
    const [AudioPreview, setAudioPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [PhotofileSize, setPhotoFileSize] = useState('');
    const [AudiofileSize, setAudioFileSize] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!categorie) {
            toast.error('Veuillez sélectionner une catégorie.');
            return;
        }

        


        // Validez les champs un par un
      

        if (!categorie) {
            toast.error('Veuillez sélectionner une catégorie.');
            return;
        }

        if (categorie.value == "video") {

            if(getYouTubeVideoID(video_url))
            {
                console.log(getYouTubeVideoID(video_url))
            }
            else
            {
                toast.error('Please Type a Youtube Link');
            return;
            }
        }
        if (!title) {
            toast.error('Veuillez saisir un title.');
            return;
        }

        if (!description) {
            toast.error('Veuillez saisir un numéro de téléphone.');
            return;
        }

        

        // Si toutes les validations sont passées, continuez ici
        setLoading(true);

        try {

            if (categorie.value == "text") {
                const formData = new FormData();
                formData.append('post_id', id);
                formData.append('title', title);
                formData.append('content_type', categorie.value);
                formData.append('description', description);
                formData.append('file', photo);
                formData.append('lang', lang);
                formData.append('file_size', PhotofileSize);
             
                await axios.post('https://ishure-abarundi.webserver.control.stat-house.org/api/v2/ishure/newpostcontent/photo', formData);
                toast.success('Formulaire soumis avec succès !');
                settitle('');
                setCategorie(null);
                setdescription('');
                setPhoto(null);
                setPreview(null);
                history('/Posts')
            }

            if (categorie.value == "video") {
                 let videoId=getYouTubeVideoID(video_url)
                const data = {
                    'post_id': id,
                    'title': title,
                    'content_type': categorie.value,
                    'description': description,
                    'lang': lang,
                    'video_url': videoId
                }
                
                await axios.post('https://ishure-abarundi.webserver.control.stat-house.org/api/v2/ishure/newpostcontent/video', data);
                toast.success('Formulaire soumis avec succès !');
                settitle('');
                setCategorie(null);
                setdescription('');
                setvideo_url('');
                history('/Posts')
            }

            if (categorie.value == "audio") {
                const formData = new FormData();
                formData.append('post_id', id);
                formData.append('title', title);
                formData.append('content_type', categorie.value);
                formData.append('description', description);
                formData.append('file', Audio);
                formData.append('lang', lang);
                formData.append('file_size', AudiofileSize);
               
                await axios.post('https://ishure-abarundi.webserver.control.stat-house.org/api/v2/ishure/newpostcontent/audio', formData);
                toast.success('Formulaire soumis avec succès !');
                settitle('');
                setCategorie(null);
                setdescription('');
                setAudio(null);
                setAudioPreview(null);
                history('/Posts')
            }
            // Envoi des données à l'API

        } catch (error) {
            console.log(error)
            toast.error('Une erreur s\'est produite lors de la soumission du formulaire.');
        } finally {
            setLoading(false);
        }
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const handlePhotoChange = (e) => {
        const selectedPhoto = e.target.files[0];
        if (selectedPhoto) {
            setPhoto(selectedPhoto);
            console.log(selectedPhoto.size)
            setPhotoFileSize(selectedPhoto.size)
            console.log(formatBytes(selectedPhoto.size))
            const reader = new FileReader();
            reader.onload = (event) => {
                setPreview(event.target.result);
            };
            reader.readAsDataURL(selectedPhoto);
        }
    };



    const handleAudioChange = (e) => {
        const selectedAudio = e.target.files[0];
        if (selectedAudio) {
            setAudio(selectedAudio);
            console.log(selectedAudio.size)
            console.log(formatBytes(selectedAudio.size))
            setAudioFileSize(selectedAudio.size)
            setAudioPreview(URL.createObjectURL(selectedAudio));
        }
    };

    function getLanguageName(code) {
        switch (code.toLowerCase()) {
            case 'fr':
                return 'French';
            case 'en':
                return 'English';
            case 'ki':
                return 'Kirundi';
            default:
                return 'Unknown language code';
        }
    }

    function getYouTubeVideoID(url) {
        // Regular expression to match YouTube video ID patterns from various formats
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|e)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        
        // Match the video ID using the regex
        const match = url.match(regex);
        
        // If match is found, return the video ID, otherwise return null
        return match ? match[1] : null;
    }

    return (
        <>
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title fw-semibold mb-4">
                            Post Creation {getLanguageName(lang)} Version
                        </h5>
                        <div class="card">
                            <div class="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="title" className="form-label">
                                            Post Title
                                        </label>
                                        <input
                                            type="text"
                                            id="title"
                                            placeholder="Post Title "
                                            className="form-control"
                                            value={title}
                                            onChange={(e) => settitle(e.target.value)}
                                        />
                                    </div>


                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label">
                                            Post Description
                                        </label>
                                        <textarea
                                            placeholder="Post Description"
                                            class="form-control"
                                            rows="5"
                                            id="Description"
                                            value={description}
                                            onChange={(e) => setdescription(e.target.value)}
                                        ></textarea>
                                    </div>


                                    <div className="mb-4">
                                        <label htmlFor="categorie" className="form-label">
                                            Catégorie
                                        </label>
                                        <Select
                                            id="categorie"
                                            options={categories}
                                            value={categorie}
                                            onChange={setCategorie}
                                        />
                                    </div>

                                    {
                                        categorie?.value == "video" &&
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label">
                                                Video Link
                                            </label>
                                            <input
                                                type="description"
                                                id="description"
                                                placeholder="Video Link Here"
                                                className="form-control"
                                                value={video_url}
                                                onChange={(e) => setvideo_url(e.target.value)}
                                            />
                                        </div>
                                    }

                                    {
                                        categorie?.value == "text" &&
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Photo
                                            </label>

                                            <input
                                                type="file"
                                                id="photo"
                                                className="form-control visually-hidden"
                                                accept="image/*"
                                                onChange={handlePhotoChange}
                                            />
                                            <div className="upload-icon">
                                                <label htmlFor="photo" className="btn btn-primary">
                                                    Télécharger <i className="fas fa-upload mb-2"></i>
                                                </label>
                                            </div>
                                            {photo && (
                                                <img src={preview} alt="Photo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                            )}
                                        </div>

                                    }

                                    {
                                        categorie?.value == "audio" &&
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Audio
                                            </label>
                                            <input
                                                type="file"
                                                id="photo"
                                                className="form-control visually-hidden"
                                                accept="audio/*"
                                                onChange={handleAudioChange}
                                            />
                                            <div className="upload-icon">
                                                <label htmlFor="photo" className="btn btn-primary">
                                                    Télécharger <i className="fas fa-upload mb-2"></i>
                                                </label>
                                            </div>
                                            {Audio && (

                                                <div className="mb-4 ">
                                                    <h6 className="text-sm text-gray-200 font-medium mb-2">Audio Preview:</h6>
                                                    <audio
                                                        controls
                                                        className="w-full max-w-md border border-gray-300 bg-gray-dark rounded-lg shadow-md"
                                                    >
                                                        <source src={AudioPreview} type={Audio.type} />
                                                        Your browser does not support the audio tag.
                                                    </audio>
                                                </div>

                                            )}
                                        </div>


                                    }


                                    <button type="submit" className="btn btn-primary float-end" disabled={loading}>
                                        {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewPostContent;
