
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

function NouveauEvenement() {
  const [current_index, setCurrent_index] = useState(0);
  const [formErrors, setFormErrors] = useState({
    Description: "",
    Photo: "",
  });
  const [formData, setFormData] = useState({
    Titre: "",
    Description: "",
    Photo: "",
    File: "",
    Contenu: [{ Photo: "", File: "", Lien: "" }],
  });

  const onDrop = (acceptedFiles) => {
    AddImage(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleContenuChange = (index, event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => {
      const updatedContenu = [...prevFormData.Contenu];
      updatedContenu[index] = {
        ...updatedContenu[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        Contenu: updatedContenu,
      };
    });
  };

  const handleAddContenu = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Contenu: [
        ...prevFormData.Contenu,
        { Sous_Titre: "", Description: "", Photo: "", Lien: "" },
      ],
    }));
  };

  const handleDeleteContenu = (index) => {
    setFormData((prevFormData) => {
      const updatedContenu = [...prevFormData.Contenu];
      updatedContenu.splice(index, 1);
      return {
        ...prevFormData,
        Contenu: updatedContenu,
      };
    });
  };

  const validateForm = () => {
    let isValid = true;
    // Validate Titre field
    if (formData.Titre.trim() === "") {
      toast.error("Titre de l'evenement est Obligatoire");
      isValid = false;
      return isValid;
    }

    // Validate Description field
    if (formData.Description.trim() === "") {
      toast.error("Description de l'evenement est Obligatoire");
      isValid = false;
      return isValid;
    }

    //Validate Contenu fields
    formData.Contenu.forEach((contenu, index) => {
      if (contenu.Photo.trim() === "") {
        toast.error(`Contenu ${index}.Sous Titre est Obligatoire`);
        isValid = false;
        return isValid;
      }
    });
    return isValid;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("submit");
    if (validateForm()) {
      console.log(formData);
    }
  };

  const AddImage = (acceptedFiles) => {
    if (current_index === -1) {
      console.log(current_index);
      if (acceptedFiles.length > 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          Photo: URL.createObjectURL(acceptedFiles[0]),
          File: acceptedFiles[0],
        }));
      }
    } else {
      console.log(current_index);
      console.log(acceptedFiles[0])
      if (acceptedFiles.length > 0) {
        setFormData((prevFormData) => {
          const updatedContenu = [...prevFormData.Contenu];
          updatedContenu[current_index] = {
            ...updatedContenu[current_index],
            Photo: URL.createObjectURL(acceptedFiles[0]),
            File: acceptedFiles[0],
          };

          return {
            ...prevFormData,
            Contenu: updatedContenu,
          };
        });
      }
    }
  };

  return (
    <>
      {" "}
      <div class="container-fluid">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title fw-semibold mb-4">Nouveau Evenement</h5>
              <div class="card">
                <div class="card-body">
                  <form onSubmit={handleSubmit}>
                    <div class="mb-3">
                      <label for="titre" class="form-label">
                        Titre
                      </label>

                      <textarea
                        placeholder="Titre de l'evenement"
                        class="form-control"
                        rows="2"
                        id="titre"
                        type="text"
                        name="Titre"
                        value={formData.Titre}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <br />

                    <div class="mb-3">
                      <label for="titre" class="form-label">
                        Description:
                      </label>

                      <textarea
                        placeholder="Description de la publication"
                        class="form-control"
                        rows="4"
                        id="Description"
                        type="text"
                        name="Description"
                        value={formData.Description}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>

                    <br />

                    <h2>Gallery Image:</h2>
                    {formData.Contenu.map((item, index) => (
                      <div key={index} class="bg-secondary p-2 rounded my-5">
                        <div class="mb-3">
                          <label for="titre" class="form-label">
                            Photo:
                          </label>
                          <div
                            {...getRootProps()}
                            className={`dropzone ${isDragActive ? "active" : ""
                              }`}
                          >
                            <input {...getInputProps()} />
                            {formData.Contenu[index].Photo ? (
                              <>
                                <div className="w- h- rounded overflow-hidden">
                                  <img
                                    class="img-fluid rounded"
                                    src={formData.Contenu[index].Photo}
                                    style={{
                                      height: "160px",
                                      width: "auto",
                                      "object-fit": "contain",
                                    }}
                                  />
                                  <div
                                    onClick={() => {
                                      setFormData((prevFormData) => {
                                        const updatedContenu = [
                                          ...prevFormData.Contenu,
                                        ];
                                        updatedContenu[index] = {
                                          ...updatedContenu[index],
                                          Photo: "",
                                          File: "",
                                        };

                                        return {
                                          ...prevFormData,
                                          Contenu: updatedContenu,
                                        };
                                      });
                                    }}
                                    class="btn btn-primary rounded-end-4 ms-sm-n1"
                                  >
                                    X
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  onClick={() => {
                                    setCurrent_index(index);
                                  }}
                                  class="btn btn-primary"
                                >
                                  Ajouter Une Image
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="titre" class="form-label">
                            Lien:
                          </label>
                          <textarea
                            placeholder="Lien du Video "
                            class="form-control"
                            rows="1"
                            id="Lien"
                            type="text"
                            name="Lien"
                            value={item.Lien}
                            onChange={(event) =>
                              handleContenuChange(index, event)
                            }
                          ></textarea>
                        </div>

                        <br />
                        <button
                          type="button"
                          class="btn btn-primary ml-2"
                          onClick={() => handleDeleteContenu(index)}
                        >
                          Supprimer Ce Gallery
                        </button>
                        <hr />
                      </div>
                    ))}
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={handleAddContenu}
                    >
                      Ajouter Gallery
                    </button>
                    <br />
                  </form>
                </div>
              </div>
              <button
                class="btn btn-primary"
                onClick={handleSubmit}
                type="submit"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NouveauEvenement;
