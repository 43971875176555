import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function EditerCooperation() {
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [titre, setTitre] = useState('');
  const [logo, setLogo] = useState(null);
  const [newLogo, setNewLogo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Récupérer les données de la société depuis l'API
    axios.get(`http://localhost:4000/societe/${id}`)
      .then((response) => {
        const { societe, logo, categorie } = response.data;
        setData(response.data);
        setTitre(societe);
        setSelectedDomain({ value: categorie, label: categorie });
        setLogo(`http://localhost:4000/uploads/cooperation/${logo}`);
      })
      .catch((error) => {
        console.log(error.message)
      });
  }, [id]);

  const categoryOptions = [
    { value: 'International', label: 'International' },
    { value: 'National', label: 'National' },
  ];

  const handleDomainChange = (selectedOption) => {
    setSelectedDomain(selectedOption);
  };

  const handleLogoDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setNewLogo(file);
      setLogo(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validez les champs ici
    if (!selectedDomain) {
      toast.error('Veuillez sélectionner une catégorie.');
      return;
    }

    if (!titre) {
      toast.error('Veuillez saisir un nom.');
      return;
    }

    if (!logo) {
      toast.error('Veuillez télécharger un logo.');
      return;
    }

    const formData = new FormData();
    formData.append('categorie', selectedDomain.value);
    formData.append('societe', titre);
    if (newLogo) {
      formData.append('photo', newLogo);
    }
    setIsSubmitting(true);

    console.log(formData);

    try {
      // Envoyez les données à l'API en utilisant Axios pour la mise à jour
      await axios.put(`http://localhost:4000/societe/modify/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Société mise à jour avec succès.');
      navigate('/Cooperation'); // Utilisez navigate pour la navigation
    } catch (error) {
      toast.error('Une erreur s\'est produite lors de l\'envoi du formulaire.');
    } finally {
      setIsSubmitting(false);
    }


  };

  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title fw-semibold mb-4">
            Editer la société
          </h5>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="categorie" className="form-label">
                    Catégorie
                  </label>
                  <Select
                    id="categorie"
                    options={categoryOptions}
                    value={selectedDomain}
                    onChange={handleDomainChange}
                    placeholder="Catégorie de la société"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="nom" className="form-label">
                    Nom
                  </label>
                  <input
                    type="text"
                    placeholder="Nom de la société"
                    className="form-control"
                    id="nom"
                    value={titre}
                    onChange={(e) => setTitre(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="logo" className="form-label">
                    Logo
                  </label>
                  <Dropzone onDrop={handleLogoDrop} accept="image/*">
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        {!logo ? (
                          <div className="upload-icon">
                            <button type="button" className="btn btn-primary">Télécharger  <i className="fas fa-upload mb-2"></i></button>
                          </div>
                        ) : (
                          <div className="uploaded-logo-container">
                            <img
                              src={logo}
                              alt="Logo"
                              className="uploaded-logo"
                              style={{ maxWidth: '100%', maxHeight: '200px' }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </div>
                <button type="submit" className="btn btn-primary float-end" disabled={isSubmitting}>
                  {isSubmitting ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditerCooperation;
