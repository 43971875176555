import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";

function Cooperation() {
  const [cooperations, setCooperations] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    // Faites une requête GET à votre API pour récupérer les données paginées
    axios
      .get(
        `http://localhost:4000/societe/getAll?page=${page}&pageSize=${pageSize}`
      )
      .then((response) => {
        setCooperations(response.data.rows);
        setTotalPages(Math.ceil(response.data.count / pageSize));
      })
      .catch((error) =>
        console.error("Erreur lors de la récupération des données:", error)
      )
      .finally(() => setLoading(false));
  }, [page, pageSize]);

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleConfirm = (idsociete) => {
    setLoading(true);
    Swal.fire({
      title: "Etes-Vous Sur ?",
      text: "Supprimer un de tes Cooperation ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "No",
      reverseButtons: true,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`http://localhost:4000/societe/delete/${idsociete}`)
          .then((response) => {
            const updatedCooperations = cooperations.filter(
              (cooperation) => cooperation.id !== idsociete
            );
            setCooperations(updatedCooperations);
            toast.success("Societe supprimé avec succès.");
          })
          .catch((error) =>
            console.error("Erreur lors de la suppression de la Societe:", error)
          )
          .finally(() => setLoading(false));
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        toast.success("vous avez annuler la suppression de la Societe.");
        setLoading(false);
      }
    });
  };
  return (
    <>
      {/* <!-- Blog Start --> */}
      <div class="container-fluid ">
        <div class="container  pb-3">
          <div class="text-center mb-5">
            <h5
              class="text-primary text-uppercase mb-3"
              style={{ "letter-spacing": "5px" }}
            >
              Nos Cooperation
            </h5>
            <Link to={`/Cooperation/Nouveau`} class="btn btn-primary">
              Nouveau Cooperation
            </Link>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card">
                <div className="card-body p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title fw-semibold mb-3">
                      Cooperation Nationale/Internationale
                    </h5>
                    <select
                      id="pageSizeSelect"
                      className="form-select form-select-sm w-auto"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(parseInt(e.target.value));
                        setPage(1);
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>

                  <div className="table-responsive">
                    {loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">
                            Chargement en cours...
                          </span>
                        </div>
                      </div>
                    ) : (
                      <table className="table text-nowrap mb-0 align-middle">
                        <thead class="text-dark fs-4">
                          <tr>
                            <th class="border-bottom-0">
                              <h6 class="fw-semibold mb-0">logo</h6>
                            </th>
                            <th class="border-bottom-0">
                              <h6 class="fw-semibold mb-0">title</h6>
                            </th>

                            <th class="border-bottom-0">
                              <h6 class="fw-semibold mb-0">Type</h6>
                            </th>

                            <th class="border-bottom-0">
                              <h6 class="fw-semibold mb-0">Action</h6>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cooperations &&
                            cooperations.map((cooperation) => (
                              <tr key={cooperation.id}>
                                <td className="border-bottom-0">
                                  <img
                                    src={`http://localhost:4000/uploads/cooperation/${cooperation.logo}`}
                                    alt=""
                                    className="circle-img"
                                  />
                                </td>

                                <td className="border-bottom-0">
                                  <h6 className="fw-semibold mb-1">
                                    {cooperation.societe}
                                  </h6>
                                </td>
                                <td className="border-bottom-0">
                                  <h6 className="fw-semibold mb-1">
                                    {cooperation.categorie}
                                  </h6>
                                </td>
                                <td className="border-bottom-0">
                                  <div className="d-flex bg-white">
                                    <Link
                                      to={`/Cooperation/Editer/${cooperation.id}`}
                                      className="btn btn-outline-primary mx-1 d-block"
                                    >
                                      Editer
                                    </Link>
                                    <button
                                      onClick={() =>
                                        handleConfirm(cooperation.id)
                                      }
                                      className="btn btn-outline-primary mx-1 d-block"
                                    >
                                      Supprimer
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {!loading && (
                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className={`btn btn-primary ${
                          page === 1 ? "disabled" : ""
                        }`}
                        onClick={handlePreviousPage}
                      >
                        Précédent
                      </button>
                      <button
                        className={`btn btn-primary ml-2 ${
                          page === totalPages ? "disabled" : ""
                        }`}
                        onClick={handleNextPage}
                      >
                        Suivant
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Blog End --> */}
    </>
  );
}

export default Cooperation;
