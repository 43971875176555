import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import { toast } from "react-toastify";

function Ajouter_Chercheur() {
    const [selectedEquipe, setSelectedEquipe] = useState(null);
    const [EquipeList, setEquipeList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [chercheurs, setChercheurs] = useState([{ chercheur: null }]);
    const [chercheurOptions, setChercheurOptions] = useState([]);
    const [selectedChercheur, setSelectedChercheur] = useState(null);

    useEffect(() => {
        // Récupérer la liste des Equiperatoires depuis l'API
        axios
            .get("http://localhost:4000/equipe/getAll")
            .then((response) => {
                setEquipeList(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error.message);
                setIsLoading(false);
            });

        // Récupérer la liste des chercheurs depuis l'API
        axios
            .get("http://localhost:4000/chercheur/toaxe")
            .then((response) => {
                setChercheurOptions(
                    response.data.map((chercheur) => ({
                        value: chercheur.id,
                        label: ` ${chercheur.grade} ${chercheur.nom}`,
                    }))
                );
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, []);

    const validateForm = () => {
        if (!selectedEquipe) {
            toast.error("Veuillez sélectionner une équipe.");
            return false;
        }
        if (
            chercheurs.length === 0 ||
            chercheurs.some((item) => !item.chercheur || item.chercheur.value === null)
        ) {
            toast.error("Veuillez completer le camps incomplet du chercheur.");
            return false;
        }

        // Vérifier la sélection unique des chercheurs
        const selectedChercheurIds = chercheurs
            .filter((item) => item.chercheur)
            .map((item) => item.chercheur.value);

        if (new Set(selectedChercheurIds).size !== selectedChercheurIds.length) {
            toast.error("Veuillez sélectionner des chercheurs uniques.");
            return false;
        }

        return true;
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // Valider les champs
        if (!validateForm()) {
            return;
        }

        setLoading(true);

        // Envoyer les données du formulaire à l'API pour enregistrement
        const formData = {
            equipeId: selectedEquipe.value,
            chercheurId: chercheurs
                .map((item) => item.chercheur.value)
                .filter((value) => value),
        };

        console.log(formData);

        axios
            .post("http://localhost:4000/organisation/addmember", formData)
            .then((response) => {
                if (response.status === 201) {
                    toast.success("Chercheur de recherche enregistré avec succès !");
                    setSelectedEquipe(null);
                    setChercheurs([{ chercheur: null }]);
                    setLoading(false);
                } else {
                    toast.error("Erreur inattendue lors de l'enregistrement du chercheur.");
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error.message);
                setLoading(false);
                if (error.response) {
                    toast.error(error.response.data);
                } else {
                    toast.error("Erreur au niveau du serveur");
                }

                // Ajoutez ici l'affichage des erreurs spécifiques de "router.post('/addmember')"
                if (Array.isArray(error.response.data)) {
                    error.response.data.forEach((errMsg) => {
                        toast.error(errMsg);
                    });
                }
            });
    };




    const addChercheur = () => {
        setChercheurs([...chercheurs, { chercheur: null }]);
    };

    const removeChercheur = (index) => {
        if (index === 0) {
            // Ne supprimez pas le premier champ, car il est non modifiable
            return;
        }
        const updatedChercheurs = [...chercheurs];
        updatedChercheurs.splice(index, 1);
        setChercheurs(updatedChercheurs);
    };

    return (
        <div className="container-fluid">
            {isLoading ? (
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Chargement en cours...</span>
                    </div>
                </div>
            ) : (
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title fw-semibold mb-4">
                                Nouveau Chercheur de Recherche
                            </h5>
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="EquipeId" className="form-label">
                                                Équipe
                                            </label>
                                            <ReactSelect
                                                id="EquipeId"
                                                options={EquipeList.map((equipe) => ({
                                                    value: equipe.id,
                                                    label: equipe.titre,
                                                }))}
                                                value={selectedEquipe}
                                                onChange={(selectedOption) =>
                                                    setSelectedEquipe(selectedOption)
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Chercheurs</label>
                                            {chercheurs.map((item, index) => (
                                                <div key={index} className=" mb-3">
                                                    <ReactSelect
                                                        id={`ChercheurId-${index}`}
                                                        options={chercheurOptions}
                                                        value={item.chercheur}
                                                        onChange={(selectedOption) => {
                                                            const updatedChercheurs = [...chercheurs];
                                                            updatedChercheurs[index].chercheur = selectedOption;
                                                            setChercheurs(updatedChercheurs);
                                                        }}
                                                    />
                                                    {index === 0 ? (
                                                        ""
                                                    ) : (
                                                        <span
                                                            className="btn btn-danger"
                                                            onClick={() => removeChercheur(index)}
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={addChercheur}
                                            >
                                                Ajouter un Chercheur
                                            </button>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary float-end"
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <i className="fas fa-spinner fa-spin"></i>
                                            ) : (
                                                "Enregistrer"
                                            )}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default Ajouter_Chercheur;
