import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";

function Labo() {
  const [labos, setLabo] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    // Faites une requête GET à votre API pour récupérer les données paginées
    axios
      .get(`http://localhost:4000/labo/getAll?page=${page}&pageSize=${pageSize}`)
      .then((response) => {
        setLabo(response.data);
        console.log(response.data)
        setTotalPages(Math.ceil(response.data.count / pageSize));
      })
      .catch((error) => console.error('Erreur lors de la récupération des données:', error))
      .finally(() => setLoading(false));
  }, [page, pageSize]);



  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };


  const handleConfirm = (idlabo) => {
    setLoading(true);
    Swal.fire({
      title: "Etes-Vous Sur ?",
      text: "Supprimer un de tes labo ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "No",
      reverseButtons: true,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`http://localhost:4000/labo/delete/${idlabo}`)
          .then((response) => {
            const updatedlabos = labos.filter((labo) => labo.id !== idlabo);
            setLabo(updatedlabos);
            toast.success("Societe supprimé avec succès.")

          })
          .catch((error) => console.error('Erreur lors de la suppression de la Societe:', error))
          .finally(() => setLoading(false));
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        toast.success("vous avez annuler la suppression de la Societe.")
        setLoading(false)
      }
    });
  };
  return (
    <>
      {/* <!-- Blog Start --> */}
      <div class="container-fluid ">
        <div class="container  pb-3">
          <div class="text-center mb-5">
            <h5
              class="text-primary text-uppercase mb-3"
              style={{ "letter-spacing": "5px" }}
            >
              Nos Laboratoires
            </h5>
            <Link to={`/Laboratoire/Nouveau `} class="btn btn-primary">
              Creer Nouveau Labo
            </Link>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title fw-semibold mb-3">
                      labo Nationale/Internationale
                    </h5>
                    <select
                      id="pageSizeSelect"
                      className="form-select form-select-sm w-auto"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(parseInt(e.target.value));
                        setPage(1);
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>

                  <div className="table-responsive">
                    {loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Chargement en cours...</span>
                        </div>
                      </div>
                    ) : (

                      <table className="table text-nowrap mb-0 align-middle">
                        <thead class="text-dark fs-4">
                          <tr>
                            <th class="border-bottom-0">
                              <h6 class="fw-semibold mb-0">Nom du Labo</h6>
                            </th>

                            <th class="border-bottom-0">
                              <h6 class="fw-semibold mb-0">Action</h6>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {labos && labos.map((labo) => (
                            <tr key={labo.id}>
                              <td className="border-bottom-0">
                                <h6 className="fw-semibold mb-1">{labo.nom}</h6>
                              </td>

                              <td className="border-bottom-0">
                                <div className="d-flex bg-white">
                                  <Link
                                    to={`/Laboratoire/Editer/${labo.id}`}
                                    className="btn btn-outline-primary mx-1 d-block"
                                  >
                                    Editer
                                  </Link>
                                  <button
                                    onClick={() => handleConfirm(labo.id)}
                                    className="btn btn-outline-primary mx-1 d-block"
                                  >
                                    Supprimer
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>)}
                  </div>
                  {!loading && (
                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className={`btn btn-primary ${page === 1 ? 'disabled' : ''}`}
                        onClick={handlePreviousPage}
                      >
                        Précédent
                      </button>
                      <button
                        className={`btn btn-primary ml-2 ${page === totalPages ? 'disabled' : ''}`}
                        onClick={handleNextPage}
                      >
                        Suivant
                      </button>
                    </div>)}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Blog End --> */}
    </>
  );
}

export default Labo;
