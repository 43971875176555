import React from "react";
import { Link } from "react-router-dom";
function EditerEvenement() {
  return (
    <>
      {" "}
      <div class="container-fluid">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title fw-semibold mb-4">Editer Publication</h5>
              <div class="card">
                <div class="card-body">
                  <form>
                    <div class="mb-3">
                      <label for="titre" class="form-label">
                        Titre
                      </label>

                      <textarea
                        placeholder="Titre de la publication"
                        class="form-control"
                        rows="2"
                        id="titre"
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label for="Contenu" class="form-label">
                        Contenu
                      </label>

                      <textarea
                        placeholder="Contenu de la publication"
                        class="form-control"
                        rows="4"
                        id="Contenu"
                      ></textarea>
                    </div>

                    <button class="btn btn-primary">Enregistrer</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditerEvenement;
