import React from "react";
import { Link } from "react-router-dom";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2";
function Evenement() {
  const id = 1;

  const handleConfirm = () => {
    Swal.fire({
      title: "Etes-Vous Sur ?",
      text: "Supprimer un des evenements ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "No",
      reverseButtons: true,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked "Yes"
        // Perform the desired action
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked "No" or closed the dialog
        // Handle accordingly
      }
    });
  };
  return (
    <>
      {/* <!-- Blog Start --> */}
      <div class="container-fluid ">
        <div class="container  pb-3">
          <div class="text-center mb-5">
            <h5
              class="text-primary text-uppercase mb-3"
              style={{ "letter-spacing": "5px" }}
            >
              Nos Evenements
            </h5>
            <Link
              to={`/Evenement/Nouveau `}

              class="btn btn-primary"
            >
              Poster un Nouveau Evenement
            </Link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row g-4 event-item wow fadeIn" data-wow-delay="0.1s">
              <div class="col-12 col-lg-8  pb-3">
                <div class="ms-3">
                  <h4 class="mb-3">Milad Un Nabi</h4>
                  <div class="mb-2">
                    <a
                      class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                      href=""
                    >
                      Jan 01, 2045
                    </a>
                  </div>
                  <p class="mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in
                  </p>
                  <div class="d-flex justify-content-between bg-white  p-2">
                    <Link
                      to={`/Evenement/Editer/${id} `}
                      class="btn btn-outline-primary mx-3  d-block"
                    >
                      {" "}
                      Editer
                    </Link>

                    <button
                      onClick={handleConfirm}
                      class="btn btn-outline-primary mx-3  d-block"
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="overflow-hidden mb-5">
                  <img
                    src="img/Structures.jfif"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row g-4 event-item wow fadeIn" data-wow-delay="0.1s">
              <div class="col-12 col-lg-8  pb-3">
                <div class="ms-3">
                  <h4 class="mb-3">Milad Un Nabi</h4>
                  <div class="mb-2">
                    <a
                      class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                      href=""
                    >
                      Jan 01, 2045
                    </a>
                  </div>
                  <p class="mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in
                  </p>
                  <div class="d-flex justify-content-between bg-white  p-2">
                    <Link
                      to={`/Evenement/Editer/${id} `}
                      class="btn btn-outline-primary mx-3  d-block"
                    >
                      {" "}
                      Editer
                    </Link>

                    <button
                      onClick={handleConfirm}
                      class="btn btn-outline-primary mx-3  d-block"
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="overflow-hidden mb-5">
                  <img
                    src="img/Structures.jfif"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row g-4 event-item wow fadeIn" data-wow-delay="0.1s">
              <div class="col-12 col-lg-8  pb-3">
                <div class="ms-3">
                  <h4 class="mb-3">Milad Un Nabi</h4>
                  <div class="mb-2">
                    <a
                      class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                      href=""
                    >
                      Jan 01, 2045
                    </a>
                  </div>
                  <p class="mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in
                  </p>
                  <div class="d-flex justify-content-between bg-white  p-2">
                    <Link
                      to={`/Publication/Editer/${id} `}
                      class="btn btn-outline-primary mx-3  d-block"
                    >
                      {" "}
                      Editer
                    </Link>

                    <button
                      onClick={handleConfirm}
                      class="btn btn-outline-primary mx-3  d-block"
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="overflow-hidden mb-5">
                  <img
                    src="img/Structures.jfif"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row g-4 event-item wow fadeIn" data-wow-delay="0.1s">
              <div class="col-12 col-lg-8  pb-3">
                <div class="ms-3">
                  <h4 class="mb-3">Milad Un Nabi</h4>
                  <div class="mb-2">
                    <a
                      class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                      href=""
                    >
                      Jan 01, 2045
                    </a>
                  </div>
                  <p class="mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in
                  </p>
                  <div class="d-flex justify-content-between bg-white  p-2">
                    <Link
                      to={`/Evenement/Editer/${id} `}
                      class="btn btn-outline-primary mx-3  d-block"
                    >
                      {" "}
                      Editer
                    </Link>

                    <button
                      onClick={handleConfirm}
                      class="btn btn-outline-primary mx-3  d-block"
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="overflow-hidden mb-5">
                  <img
                    src="img/Structures.jfif"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Blog End --> */}
    </>
  );
}

export default Evenement;
