import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

function EditerAxe_Recherche() {
  const [selectedEquipe, setSelectedEquipe] = useState(null);
  const [EquipeList, setEquipeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [axes, setAxes] = useState("");
  const [data, setData] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navige = useNavigate()

  useEffect(() => {
    axios
      .get("http://localhost:4000/equipe/getAll")
      .then((response) => {
        setEquipeList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!id) return;
    axios
      .get(`http://localhost:4000/axe/getOne/${id}`)
      .then(async (response) => {
        setData(response.data);

        // Recherchez l'équipe correspondante dans EquipeList en utilisant l'ID d'équipe dans setData
        const equipe = EquipeList.find((equipe) => equipe.id === response.data.equipeId);

        setSelectedEquipe({
          value: response.data.equipeId,
          label: equipe ? equipe.titre : '', // Utilisez le champ approprié ici
        });

        // Remplissez le champ d'axe avec les données de l'axe
        setAxes(response.data.axes);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setIsLoading(false);
      });
  }, [id, EquipeList]);

  const validateForm = () => {
    if (!selectedEquipe) {
      toast.error("Veuillez sélectionner une équipe.");
      return false;
    }

    if (axes.trim() === "") {
      toast.error("Veuillez ajouter un axe non complété.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    try {
      const fomdata = {
        equipeId: selectedEquipe.value,
        axes: axes
      }
      axios.put(`http://localhost:4000/axe/modify/${id}`, fomdata).then((response) => {
        toast.success("Axe de recherche modifié avec succès !");
        navige("/Recherches")
      }).catch((error) => {
        console.log(error.message)
        if (error.response) {
          console.log(error.response.data)
        }
      })
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Le reste de votre code reste inchangé

  return (
    <div className="container-fluid">
      {isLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement en cours...</span>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title fw-semibold mb-4">Editer Axe de Recherche</h5>
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="EquipeId" className="form-label">
                        Équipe
                      </label>
                      <ReactSelect
                        id="EquipeId"
                        options={EquipeList.map((Equipe) => ({
                          value: Equipe.id,
                          label: Equipe.titre,
                        }))}
                        value={selectedEquipe}
                        onChange={(selectedOption) => setSelectedEquipe(selectedOption)}
                        isDisabled={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Axe de Recherche</label>
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Axe de Recherche"
                          value={axes}
                          onChange={(e) => {
                            setAxes(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary float-end" disabled={loading}>
                      {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditerAxe_Recherche;
