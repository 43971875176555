import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function Sidebar() {
  const history = useNavigate();
  const { pathname } = useLocation();
  let Profile = /^\/Profile.*$/;
  let Change_Password = /^\/Change_Password.*$/;
  let Change_Profile = /^\/Change_Profile.*$/;
  let Tableau_de_bord = /^\/Tableau_de_bord.*$/;
  let Posts = /^\/Posts.*$/;
  let Recherches = /^\/Recherches.*$/;
  let Publication = /^\/Publication.*$/;
  let Evenement = /^\/Evenement.*$/;
  let Axe_de_Recherche = /^\/Axe_de_Recherche.*$/;
  let Cooperation = /^\/Cooperation.*$/;
  let Laboratoire = /^\/Laboratoire.*$/;
  let Equipe = /^\/Equipe.*$/;
  let Organisation = /^\/Organisation.*$/;
  let Change_Mot_de_Passe = /^\/Change_Mot_de_Passe.*$/;

  function logout() {
    if (localStorage.getItem("criet_userRole")) {
      localStorage.removeItem("criet_userRole");
    }
    history("/");
    window.location.reload();
  }

  return (
    <>
      {/* <!-- Sidebar Start --> */}
      <aside class="left-sidebar">
        {/* <!-- Sidebar scroll--> */}
        <div>
          <div class="brand-logo d-flex align-items-center justify-content-between">
            <div class="d-inline-flex align-items-center">
              <img
                class="img-fluid p-2"
                src="img/ishure-logo.png"
                style={{ height: "80px", width: "80px" }}
              />
              <Link to="/" class="ml-2 d-inline-flex align-items-center">
                <h1 class="m-0">
                  <span class="text-primary">ISHURE</span>
                </h1>
              </Link>
            </div>
            <div
              class="close-btn p-1 d-xl-none d-block sidebartoggler cursor-pointer"
              id="sidebarCollapse"
            >
              <i class="ti ti-x fs-8"></i>
            </div>
          </div>
          {/* <!-- Sidebar navigation--> */}
          <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
            <ul id="sidebarnav">
              <li class="nav-small-cap">
                <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span class="hide-menu">MENU</span>
              </li>
              <li class="sidebar-item">
                {Tableau_de_bord.test(pathname) || pathname === "/" ? (
                  <Link
                    class="sidebar-link-active"
                    to="/Tableau_de_bord"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-layout-dashboard"></i>
                    </span>
                    <span class="hide-menu">Tableau de bord</span>
                  </Link>
                ) : (
                  <Link
                    class="sidebar-link"
                    to="/Tableau_de_bord"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-layout-dashboard"></i>
                    </span>
                    <span class="hide-menu">Tableau de bord</span>
                  </Link>
                )}
              
                {Posts.test(pathname) ? (
                  <Link
                    class="sidebar-link-active"
                    to="/Posts"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-checklist"></i>
                    </span>
                    <span class="hide-menu">Posts</span>
                  </Link>
                ) : (
                  <Link
                    class="sidebar-link"
                    to="/Posts"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-checklist"></i>
                    </span>
                    <span class="hide-menu">Posts</span>
                  </Link>
                )}
               

               <li class="d-none"> {Laboratoire.test(pathname) ? (
                  <Link
                    class="sidebar-link-active"
                    to="/Laboratoire"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-air-conditioning"></i>
                    </span>
                    <span class="hide-menu">Laboratoire</span>
                  </Link>
                ) : (
                  <Link
                    class="sidebar-link"
                    to="/Laboratoire"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-air-conditioning"></i>
                    </span>
                    <span class="hide-menu">Laboratoire</span>
                  </Link>
                )}
                {Equipe.test(pathname) ? (
                  <Link
                    class="sidebar-link-active"
                    to="/Equipe"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-cricket"></i>
                    </span>
                    <span class="hide-menu">Equipe</span>
                  </Link>
                ) : (
                  <Link class="sidebar-link" to="/Equipe" aria-expanded="false">
                    <span>
                      <i class="ti ti-cricket"></i>
                    </span>
                    <span class="hide-menu">Equipe</span>
                  </Link>
                )}
                   {Recherches.test(pathname) ? (
                  <Link
                    class="sidebar-link-active"
                    to="/Recherches"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-cards"></i>
                    </span>
                    <span class="hide-menu">Recherches</span>
                  </Link>
                ) : (
                  <Link
                    class="sidebar-link"
                    to="/Recherches"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-cards"></i>
                    </span>
                    <span class="hide-menu">Recherches</span>
                  </Link>
                )}
                {Publication.test(pathname) ? (
                  <Link
                    class="sidebar-link-active"
                    to="/Publication"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-file-description"></i>
                    </span>
                    <span class="hide-menu">Publication</span>
                  </Link>
                ) : (
                  <Link
                    class="sidebar-link"
                    to="/Publication"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-file-description"></i>
                    </span>
                    <span class="hide-menu">Publication</span>
                  </Link>
                )}

{Evenement.test(pathname) ? (
                  <Link
                    class="sidebar-link-active"
                    to="/Evenement"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-360-view"></i>
                    </span>
                    <span class="hide-menu">Evenement</span>
                  </Link>
                ) : (
                  <Link
                    class="sidebar-link"
                    to="/Evenement"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-360-view"></i>
                    </span>
                    <span class="hide-menu">Evenement</span>
                  </Link>
                )}

                {Cooperation.test(pathname) ? (
                  <Link
                    class="sidebar-link-active"
                    to="/Cooperation"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-activity"></i>
                    </span>
                    <span class="hide-menu">Cooperation</span>
                  </Link>
                ) : (
                  <Link
                    class="sidebar-link"
                    to="/Cooperation"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-activity"></i>
                    </span>
                    <span class="hide-menu">Cooperation</span>
                  </Link>
                )}
                
                
                </li>
             

                
              </li>
              <li class="nav-small-cap">
                <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span class="hide-menu">COMPTE</span>
              </li>
              <li class="sidebar-item">
                {Change_Password.test(pathname) ? (
                  <Link
                    class="sidebar-link-active"
                    to="/Change_Password"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-password"></i>
                    </span>
                    <span class="hide-menu">Changer Mot de Passe</span>
                  </Link>
                ) : (
                  <Link
                    class="sidebar-link"
                    to="/Change_Password"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-password"></i>
                    </span>
                    <span class="hide-menu">Changer Mot de Passe</span>
                  </Link>
                )}

                {Profile.test(pathname) ? (
                  <Link
                    class="sidebar-link-active"
                    to="/Profile"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-user"></i>
                    </span>
                    <span class="hide-menu">Mon Profile</span>
                  </Link>
                ) : (
                  <Link
                    class="sidebar-link"
                    to="/Profile"
                    aria-expanded="false"
                  >
                    <span>
                      <i class="ti ti-user"></i>
                    </span>
                    <span class="hide-menu">Mon Profile</span>
                  </Link>
                )}

                <div
                  onClick={logout}
                  class="sidebar-link"
                  aria-expanded="false"
                >
                  <span>
                    <i class="ti ti-login"></i>
                  </span>
                  <span class="hide-menu">Se Deconnecter</span>
                </div>
              </li>
            </ul>
          </nav>
          {/* <!-- End Sidebar navigation --> */}
        </div>
        {/* <!-- End Sidebar scroll--> */}
      </aside>
    </>
  );
}

export default Sidebar;
