import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

function NouveauBlog() {
  const chercheurId = 2
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blogData, setBlogData] = useState({
    domaine: "",
    titre: "",
    partie: [
      {
        soustitre: "",
        description: "",
        photo: null,
        lien: "",
        isMainPartie: true,
        imagePreview: null,
      },
    ],
  });

  const handleAddPartie = () => {
    setBlogData((prevData) => ({
      ...prevData,
      partie: [
        ...prevData.partie,
        {
          soustitre: "",
          description: "",
          photo: null,
          lien: "",
          isMainPartie: false,
          imagePreview: null,
        },
      ],
    }));
  };

  const handleRemovePartie = (index) => {
    const updatedParties = [...blogData.partie];
    updatedParties.splice(index, 1);
    setBlogData({
      ...blogData,
      partie: updatedParties,
    });
  };

  const handleInputChange = (partieIndex, field, value) => {
    const updatedParties = [...blogData.partie];
    updatedParties[partieIndex][field] = value;

    if (field === "photo") {
      const file = value;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          updatedParties[partieIndex].imagePreview = e.target.result;
          setBlogData({
            ...blogData,
            partie: updatedParties,
          });
        };
        reader.readAsDataURL(file);
      } else {
        updatedParties[partieIndex].imagePreview = null;
      }
    }

    setBlogData({
      ...blogData,
      partie: updatedParties,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Valider les champs requis
    const isFormValid =
      blogData.domaine.trim() !== "" &&
      blogData.titre.trim() !== "" &&
      blogData.partie.every(
        (partie) =>
          partie.soustitre.trim() !== "" && partie.description.trim() !== ""
      );

    if (!isFormValid) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("chercheurId", chercheurId);
      formData.append("domaine", blogData.domaine);
      formData.append("titre", blogData.titre);

      blogData.partie.forEach((partie, index) => {
        formData.append(`partie[${index}][soustitre]`, partie.soustitre);
        formData.append(`partie[${index}][description]`, partie.description);
        formData.append(`partie[${index}][lien]`, partie.lien);
        if (partie.photo) {
          formData.append(`partie[${index}][photo]`, partie.photo);
        }
      });
      setIsSubmitting(true);
      const response = await axios.post("http://localhost:4000/publicite/uploadBlog", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        // Gérez le succès de l'envoi
        toast.success("Blog enregistré avec succès !");
      } else {
        // Gérez les erreurs en cas d'échec de l'envoi
        toast.error("Erreur lors de l'envoi du blog.");
      }
    } catch (error) {
      console.error(error.message);
      toast.error("Erreur lors de l'envoi du blog.");
    } finally {
      setIsSubmitting(false); // Réinitialisez isSubmitting à false après la soumission du formulaire.
    }
  };



  return (
    <div className="container-fluid">
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title fw-semibold mb-4">Nouveau Blog</h5>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="domaine" className="form-label">
                      Domaine
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="domaine"
                      value={blogData.domaine}
                      onChange={(e) =>
                        setBlogData({
                          ...blogData,
                          domaine: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="titre" className="form-label">
                      Titre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="titre"
                      value={blogData.titre}
                      onChange={(e) =>
                        setBlogData({
                          ...blogData,
                          titre: e.target.value,
                        })
                      }
                    />
                  </div>


                  {blogData.partie.map((partie, partieIndex) => (
                    <>
                      <hr style={{ border: '1px solid #000' }} />
                      <div key={partieIndex}>
                        <div className={`mb-3 ${partie.isMainPartie ? "main-partie" : ""}`}>
                          <label htmlFor={`soustitre-${partieIndex}`} className="form-label">
                            Sous-titre
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={`soustitre-${partieIndex}`}
                            value={partie.soustitre}
                            onChange={(e) =>
                              handleInputChange(partieIndex, "soustitre", e.target.value)
                            }
                          />
                        </div>
                        <div className={`mb-3 ${partie.isMainPartie ? "main-partie" : ""}`}>
                          <label htmlFor={`description-${partieIndex}`} className="form-label">
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id={`description-${partieIndex}`}
                            value={partie.description}
                            onChange={(e) =>
                              handleInputChange(partieIndex, "description", e.target.value)
                            }
                          ></textarea>
                        </div>

                        <div className={`mb-3 ${partie.isMainPartie ? "main-partie" : ""}`}>
                          <label htmlFor={`photo-${partieIndex}`} className="form-label">
                            Photo
                          </label>
                          <div className="upload-photo-container">
                            {partie.imagePreview ? (
                              <div className="uploaded-photo">
                                <img
                                  src={partie.imagePreview}
                                  alt={`Preview of ${partie.soustitre}`}
                                  className="img-preview"
                                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                                />
                                <button
                                  type="button"
                                  className="btn btn-danger remove-photo"
                                  onClick={() => {
                                    handleInputChange(partieIndex, "photo", null);
                                  }}
                                >
                                  <i className="fas fa-trash mb-2"></i>
                                </button>
                              </div>
                            ) : (
                              <div className="upload-icon">
                                <input
                                  type="file"
                                  className="d-none"
                                  id={`photo-${partieIndex}`}
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleInputChange(partieIndex, "photo", e.target.files[0])
                                  }
                                />
                                <label htmlFor={`photo-${partieIndex}`} className="btn btn-primary">
                                  Télécharger <i className="fas fa-upload mb-2"></i>
                                </label>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className={`mb-3 ${partie.isMainPartie ? "main-partie" : ""}`}>
                          <label htmlFor={`lien-${partieIndex}`} className="form-label">
                            Lien
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={`lien-${partieIndex}`}
                            value={partie.lien}
                            onChange={(e) =>
                              handleInputChange(partieIndex, "lien", e.target.value)
                            }
                          />
                        </div>
                        {!partie.isMainPartie && (
                          <div className="mb-3 text-center"> {/* Utilisez text-center pour centrer verticalement */}
                            <button
                              type="button"
                              className="btn btn-danger mx-auto"
                              onClick={() => handleRemovePartie(partieIndex)}
                            >
                              Supprimer la partie
                            </button>
                          </div>

                        )}
                      </div>
                    </>
                  ))}
                  <hr style={{ border: '1px solid #000' }} />

                  <button type="button" className="btn btn-primary" onClick={handleAddPartie}>
                    Ajouter une partie
                  </button>

                  <button type="submit" className="btn btn-primary float-end mt-2" disabled={isSubmitting}>
                    {isSubmitting ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default NouveauBlog;
