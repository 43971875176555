import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
function EditerLabo() {
  const { id } = useParams()
  const [nom, setNom] = useState()
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false);


  useEffect(() => {
    axios.get(`http://localhost:4000/labo/${id}`)
      .then((response) => {
        const { nom } = response.data;
        setNom(nom);
      })
      .catch((error) => {
        console.log(error.message)
      });
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nom) {
      toast.error('Veuillez saisir un nom.');
      return;
    }
    try {
      // Envoyez les données à l'API en utilisant Axios pour la mise à jour
      await axios.put(`http://localhost:4000/labo/modify/${id}`, { nom: nom });
      toast.success('Labo mise à jour avec succès.');
      navigate('/Laboratoire');
    } catch (error) {
      toast.error('Une erreur s\'est produite lors de l\'envoi du formulaire.');
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      {" "}
      <div class="container-fluid">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title fw-semibold mb-4">
                Editer Axe de Recherche
              </h5>
              <div class="card">
                <div class="card-body">
                  <form onSubmit={handleSubmit}>
                    <div class="mb-3">
                      <label for="titre" class="form-label">
                        Nom du Labo
                      </label>

                      <input
                        type="text"
                        placeholder="Nom de la Labo"
                        className="form-control"
                        id="nom"
                        value={nom}
                        onChange={(e) => setNom(e.target.value)}
                      />
                    </div>

                    <button type="submit" className="btn btn-primary float-end" disabled={isSubmitting}>
                      {isSubmitting ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditerLabo;
