import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
function AdminNavbar() {
  const history = useNavigate();
  const { pathname } = useLocation();
  function logout() {
    if (localStorage.getItem("ishure-adx")) {
      localStorage.removeItem("ishure-adx");
    }
    history("/");
    window.location.reload();
  }
  if (pathname !== "/connexion") {
    return (
      <>
        {/* <!--  Header Start --> */}
        <header class="app-header border-bottom">
          <nav class="navbar navbar-expand-lg navbar-light">
            <ul class="navbar-nav">
              <li class="nav-item d-block d-xl-none">
                <a
                  class="nav-link sidebartoggler nav-icon-hover"
                  id="headerCollapse"
                  href="javascript:void(0)"
                >
                  <i class="ti ti-menu-2"></i>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                  <i class="ti ti-bell-ringing"></i>
                  <div class="notification bg-primary rounded-circle"></div>
                </a>
              </li>
            </ul>
            <div
              class="navbar-collapse justify-content-end px-0"
              id="navbarNav"
            >
              <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                <div onClick={logout} class="btn btn-primary">
                  Se Deconnecter
                </div>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link nav-icon-hover"
                    href="javascript:void(0)"
                    id="drop2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="img/ishure-logo.png"
                      alt=""
                      width="35"
                      height="35"
                      class="rounded-circle"
                    />
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop2"
                  >
                    <div class="message-body">
                      <a
                        href="javascript:void(0)"
                        class="d-flex align-items-center gap-2 dropdown-item"
                      >
                        <i class="ti ti-password"></i>
                        <p class="mb-0 fs-3">Changer Mot de Passe</p>
                      </a>
                      <a
                        href="javascript:void(0)"
                        class="d-flex align-items-center gap-2 dropdown-item"
                      >
                        <i class="ti ti-user fs-6"></i>
                        <p class="mb-0 fs-3">Mon Profile</p>
                      </a>

                      <div
                        onClick={logout}
                        class="btn btn-outline-primary mx-3 mt-2 d-block"
                      >
                        Deconnecter
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        {/* <!--  Header End --> */}
      </>
    );
  } else {
    return null;
  }
}

export default AdminNavbar;
