import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
function Equipe() {
    const [labos, setLabos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Récupérer les données depuis l'API
        axios
            .get("http://localhost:4000/equipe/getAllWithEquipes")
            .then((response) => {
                setLabos(response.data);
                setIsLoading(false); // Définir isLoading sur false une fois que les données sont chargées
            })
            .catch((error) => {
                console.error(error.message);
                setIsLoading(false); // Définir isLoading sur false en cas d'erreur de chargement des données
            });
    }, []);

    const handleDelete = (equipeId) => {
        Swal.fire({
            title: "Etes-Vous Sur ?",
            text: "Supprimer cette équipe ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
            reverseButtons: true,
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`http://localhost:4000/equipe/delete/${equipeId}`)
                    .then(() => {
                        setLabos((prevLabos) =>
                            prevLabos.map((labo) => ({
                                ...labo,
                                equipes: labo.equipes.filter((equipe) => equipe.id !== equipeId),
                            }))
                        );
                        toast.success("Équipe supprimée avec success");
                    })
                    .catch((error) => {
                        console.error(error.message);
                        toast.warning("Une erreur s'est produite lors de la suppression de l'équipe.");
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                toast.success("vous avez annuler la supplession de l'eqipe")
            }
        });
    };

    return (
        <>
            {/* <!-- Blog Start --> */}
            <div class="container-fluid ">
                <div class="container  pb-3">
                    <div class="text-center mb-5">
                        <h5
                            class="text-primary text-uppercase mb-3"
                            style={{ "letter-spacing": "5px" }}
                        >
                            Nos Equipes
                        </h5>
                        <Link to={`/Equipe/Nouveau `} class="btn btn-primary">
                            Nouveau Equipe
                        </Link>
                    </div>
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-12 d-flex ">
                            <div class="card w-100">
                                <div class="card-body p-4">
                                    <h5 class="card-title fw-semibold mb-4">Equipes</h5>
                                    {isLoading ? (
                                        <div className="text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Chargement en cours...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div class="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Laboratoire</th>
                                                        <th>Equipe</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {labos.map((labo) => (
                                                        <React.Fragment key={labo.id}>
                                                            <tr>
                                                                <td rowSpan={labo.equipes.length + 1}>{labo.nom}</td>
                                                            </tr>
                                                            {labo.equipes.map((equipe) => (
                                                                <tr key={equipe.id}>
                                                                    <td>{equipe.titre}</td>

                                                                    <td class="border-bottom-0">
                                                                        <div class="d-flex  bg-white  ">
                                                                            <Link
                                                                                to={`/Equipe/Editer/${equipe.id} `}
                                                                                class="btn btn-outline-primary mx-1  d-block"
                                                                            >
                                                                                {" "}
                                                                                Editer
                                                                            </Link>

                                                                            <button
                                                                                onClick={() => handleDelete(equipe.id)}
                                                                                class="btn btn-outline-danger mx-1  d-block"
                                                                            >
                                                                                Supprimer
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Blog End --> */}
        </>
    );
}

export default Equipe;
