import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';

const categories = [
    { value: 'Membre permanent', label: 'Membre permanent' },
    { value: 'Membre associé', label: 'Membre associé' },
];
const grades = [

    { value: 'Prof', label: 'Prof' },
    { value: 'Dr Ir', label: 'Dr Ir' },
    { value: 'Dr', label: 'Dr' },
    { value: 'MrScIr', label: 'MrScIr' },
    { value: 'Mr', label: 'Mr' },
    { value: 'Ir', label: 'Ir' },
    { value: 'Mme', label: 'Mme' },
];

function NouveauChercheur() {
    const [nom, setNom] = useState('');
    const [grade, setGrade] = useState(null);
    const [categorie, setCategorie] = useState(null);
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [photo, setPhoto] = useState(null);
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validez les champs un par un
        if (!nom) {
            toast.error('Veuillez saisir un nom.');
            return;
        }

        if (!grade) {
            toast.error('Veuillez sélectionner un grade.');
            return;
        }

        if (!categorie) {
            toast.error('Veuillez sélectionner une catégorie.');
            return;
        }


        if (!email) {
            toast.error('Veuillez saisir une adresse e-mail.');
            return;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            toast.error('Adresse e-mail invalide.');
            return;
        }

        if (!tel) {
            toast.error('Veuillez saisir un numéro de téléphone.');
            return;
        }

        // Si toutes les validations sont passées, continuez ici
        setLoading(true);

        try {
            // Envoi des données à l'API
            const formData = new FormData();
            formData.append('nom', nom);
            formData.append('grade', grade.value);
            formData.append('categorie', categorie.value);
            formData.append('email', email);
            formData.append('tel', tel);
            formData.append('photo', photo);

            console.log(formData)
            await axios.post('http://localhost:4000/chercheur/register', formData);
            toast.success('Formulaire soumis avec succès !');
            setNom('');
            setGrade(null);
            setCategorie(null);
            setEmail('');
            setTel('');
            setPhoto(null);
            setPreview(null);
        } catch (error) {
            toast.error('Une erreur s\'est produite lors de la soumission du formulaire.');
        } finally {
            setLoading(false);
        }
    };

    const handlePhotoChange = (e) => {
        const selectedPhoto = e.target.files[0];

        if (selectedPhoto) {
            setPhoto(selectedPhoto);
            const reader = new FileReader();
            reader.onload = (event) => {
                setPreview(event.target.result);
            };
            reader.readAsDataURL(selectedPhoto);
        }
    };

    return (
        <>
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title fw-semibold mb-4">
                            Nouveau Chercheur
                        </h5>
                        <div class="card">
                            <div class="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="nom" className="form-label">
                                            Nom Complet
                                        </label>
                                        <input
                                            type="text"
                                            id="nom"
                                            placeholder="Nom Complet du chercheur"
                                            className="form-control"
                                            value={nom}
                                            onChange={(e) => setNom(e.target.value)}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="grade" className="form-label">
                                            Grade
                                        </label>
                                        <Select
                                            id="grade"
                                            options={grades}
                                            value={grade}
                                            onChange={setGrade}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="categorie" className="form-label">
                                            Catégorie
                                        </label>
                                        <Select
                                            id="categorie"
                                            options={categories}
                                            value={categorie}
                                            onChange={setCategorie}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            id="email"
                                            placeholder="Adresse e-mail"
                                            className="form-control"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="tel" className="form-label">
                                            Numéro de téléphone
                                        </label>
                                        <input
                                            type="tel"
                                            id="tel"
                                            placeholder="Numéro de téléphone"
                                            className="form-control"
                                            value={tel}
                                            onChange={(e) => setTel(e.target.value)}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">
                                            Photo
                                        </label>

                                        <input
                                            type="file"
                                            id="photo"
                                            className="form-control visually-hidden"
                                            accept="image/*"
                                            onChange={handlePhotoChange}
                                        />
                                        <div className="upload-icon">
                                            <label htmlFor="photo" className="btn btn-primary">
                                                Télécharger <i className="fas fa-upload mb-2"></i>
                                            </label>
                                        </div>
                                        {photo && (
                                            <img src={preview} alt="Photo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                        )}
                                    </div>

                                    <button type="submit" className="btn btn-primary float-end" disabled={loading}>
                                        {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NouveauChercheur;
