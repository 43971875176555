import React, { useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import axios from 'axios';
function NouveauCooperation() {
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [titre, setTitre] = useState('');
  const [logo, setLogo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Options pour le champ de sélection de catégorie
  const categoryOptions = [
    { value: 'International', label: 'International' },
    { value: 'National', label: 'National' },
  ];

  // Fonction pour gérer le changement de sélection de catégorie
  const handleDomainChange = (selectedOption) => {
    setSelectedDomain(selectedOption);
  };

  // Fonction pour gérer le téléchargement de logo
  const handleLogoDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setLogo(file);
    }
  };
  // Fonction pour soumettre le formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validez les champs ici
    if (!selectedDomain) {
      toast.error('Veuillez sélectionner une catégorie.');
      return;
    }

    if (!titre) {
      toast.error('Veuillez saisir un nom.');
      return;
    }

    if (!logo) {
      toast.error('Veuillez télécharger un logo.');
      return;
    }
    const formData = new FormData();
    formData.append('categorie', selectedDomain.value);
    formData.append('societe', titre);
    formData.append('photo', logo);
    setIsSubmitting(true);
    console.log(formData)

    try {
      // Envoyez les données à l'API en utilisant Axios
      await axios.post('http://localhost:4000/societe/new', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSelectedDomain(null);
      setTitre('');
      setLogo(null);
      toast.success('Formulaire soumis avec succès.');
    } catch (error) {
      toast.error('Une erreur s\'est produite lors de l\'envoi du formulaire.');
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <div class="container-fluid">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title fw-semibold mb-4">Nouveau Cooperation</h5>
              <div class="card">
                <div class="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="categorie" className="form-label">
                        Catégorie
                      </label>
                      <Select
                        id="categorie"
                        options={categoryOptions}
                        value={selectedDomain}
                        onChange={handleDomainChange}
                        placeholder="catégorie de la société"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="nom" className="form-label">
                        Nom
                      </label>
                      <input
                        type="text"
                        placeholder="Nom de la société"
                        className="form-control"
                        id="nom"
                        value={titre}
                        onChange={(e) => setTitre(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="logo" className="form-label">
                        Logo
                      </label>
                      <Dropzone onDrop={handleLogoDrop} accept="image/*">
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            {!logo ? (
                              <div className="upload-icon">

                                <button type="button" className="btn btn-primary">Télécharger  <i className="fas fa-upload mb-2"></i></button>
                              </div>
                            ) : (
                              <div className="uploaded-logo-container">
                                <img
                                  src={URL.createObjectURL(logo)}
                                  alt="Logo"
                                  className="uploaded-logo"
                                  style={{ maxWidth: '100%', maxHeight: '200px' }} // Ajoutez ces styles
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>

                    <button type="submit" className="btn btn-primary float-end" disabled={isSubmitting}>
                      {isSubmitting ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NouveauCooperation;
