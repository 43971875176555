import React, { useState } from "react";
import "./login.css";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { encryptData, decryptData } from "../../../encryptionModule";
import { useEffect } from "react";
function LoginComponent({ isMember, setMember, setAdmin, isAdmin }) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("ishure-adx")) {
      const userRole = localStorage.getItem("ishure-adx");
      setAdmin(true);
      history('Tableau_de_Bord')
    }
    else {
      setAdmin(false);

    }
  }, []);
  const handleClose = () => {
    toast.dismiss();
  };
  const [Password_show, setPasswordShow] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.trim() === "" || password.trim() === "") {
      toast.error("Completer tous les Champs ");
      return;
    }
    setLoading(true);
    //  Si toutes les validations sont passées, continuez ici
    try {
      // Envoi des données à l'API
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      console.log("auth", email, password);
      const response = await axios.post(
        "https://ishure-abarundi.webserver.control.stat-house.org/api/v2/ishure/admin/login",
        {
          email,
          password,
        }
      );
      toast.success("Formulaire soumis avec succès !");
      setAdmin(true);
      const user_id = response.data.userId;
      if (localStorage.getItem("ishure-adx")) {
        localStorage.removeItem("ishure-adx");
        localStorage.setItem("ishure-adx", user_id);
        history("/Tableau_de_bord");
        setEmail("");
      setPassword("");
      }else
      {
        setEmail("");
      setPassword("");
        localStorage.setItem("ishure-adx", user_id);
        history("/Tableau_de_bord");
        
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div class="form-bg  d-flex vh-100 align-items-center justify-content-center">
        <div class="container align-items-center justify-content-center">
          <div class="row align-items-center justify-content-center pt-5">
            <div class="col-md-offset-4 col-md-5 col-sm-offset-3 col-sm-6">
              <div class="form-container border">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
                <h3 class="title mb-5">Ishure-Login</h3>
                <span class="description">
                  Type Your Login and Password , Get Connected here
                </span>
                <form class="form-horizontal p-3" onSubmit={handleSubmit}>
                  <div class="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div class="form-group d-flex align-items-center justify-center">
                    <input
                      type={Password_show ? "text" : "password"}
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {!Password_show ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        id="togglePassword"
                        className=" bi bi-eye-end-fill ml-n4 end-3 cursor-pointer z-2 opacity-50"
                        viewBox="0 0 16 16"
                        onClick={() => setPasswordShow(!Password_show)}
                        style={{ right: 20 }}
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className=" bi bi-eye-end-fill ml-n4 end-3 cursor-pointer z-2 opacity-50"
                        id="mama"
                        viewBox="0 0 16 16"
                        style={{ right: 20 }}
                        onClick={() => setPasswordShow(!Password_show)}
                      >
                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                      </svg>
                    )}
                  </div>

                  <button
                    type="submit"
                    class="btn btn-primary py-2 px-4  signup"
                    disabled={loading}
                  >
                    {loading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      "Connexion"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-center pt-5">
            <Link class="text-primary py-2 px-4  signup" to="/">
              Return Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginComponent;
