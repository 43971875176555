import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import { toast } from 'react-toastify';

function NouveauEquipe() {
    const [titre, setTitre] = useState("");
    const [selectedLabo, setSelectedLabo] = useState(null);
    const [laboList, setLaboList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // Récupérer la liste des laboratoires depuis l'API
        axios
            .get("http://localhost:4000/labo")
            .then((response) => {
                setLaboList(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error.message);
                setIsLoading(false);
            });
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();

        // Valider les champs ici
        if (!selectedLabo) {
            toast.error('Veuillez sélectionner un laboratoire.');
            return;
        }

        if (!titre) {
            toast.error('Veuillez remplir le champ Titre.');
            return;
        }

        setLoading(true);

        // Envoyer les données du formulaire à l'API pour enregistrement
        const formData = {
            titre,
            laboId: selectedLabo.value,
        };
        axios
            .post("http://localhost:4000/equipe/new", formData)
            .then((response) => {
                toast.success('Équipe enregistrée avec succès !');
                setTitre("");
                setSelectedLabo(null);

                setLoading(false);
            })
            .catch((error) => {
                console.error(error.message);
                setLoading(false);
                if (error.response) {
                    toast.error(error.response.data);
                }
            });
    };


    return (
        <div className="container-fluid">
            {isLoading ? (
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Chargement en cours...</span>
                    </div>
                </div>
            ) : (
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title fw-semibold mb-4">Nouveau Equipe</h5>
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="laboId" className="form-label">
                                                Laboratoire
                                            </label>
                                            <ReactSelect
                                                id="laboId"
                                                options={laboList.map((labo) => ({
                                                    value: labo.id,
                                                    label: labo.nom,
                                                }))}
                                                value={selectedLabo}
                                                onChange={(selectedOption) => setSelectedLabo(selectedOption)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="titre" className="form-label">
                                                Nom
                                            </label>
                                            <input
                                                placeholder="Nom de l'Équipe"
                                                className="form-control"
                                                id="titre"
                                                value={titre}
                                                onChange={(e) => setTitre(e.target.value)}
                                            />
                                        </div>

                                        <button type="submit" className="btn btn-primary float-end" disabled={loading}>
                                            {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default NouveauEquipe;
