import React from "react";
import { Link } from "react-router-dom";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2";
function Publication() {
  const id = 1;

  const handleConfirm = () => {
    Swal.fire({
      title: "Etes-Vous Sur ?",
      text: "Supprimer un des Publications ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "No",
      reverseButtons: true,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked "Yes"
        // Perform the desired action
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked "No" or closed the dialog
        // Handle accordingly
      }
    });
  };
  return (
    <>

      <div class="container-fluid ">
        <div class="container  pb-3">
          <div class="text-center mb-5">
            <h5
              class="text-primary text-uppercase mb-3"
              style={{ "letter-spacing": "5px" }}
            >
              Nos Publications
            </h5>
            <Link
              to={`/Blog/Nouveau `}

              class="btn btn-primary mx-5"
            >
              Creer un blog
            </Link>
            <Link
              to={`/Publication/Nouveau `}

              class="btn btn-primary mx-5"
            >
              ajouter un livre
            </Link>
          </div>
          <div class="row pb-3">



            <div class="col-lg-4 mb-4">
              <div class="position-relative mb-3 position-relative overflow-hidden rounded mb-2">
                <div class="bg-white border border-top-2 p-4">
                  <div class="mb-2">
                    <a
                      class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                      href=""
                    >
                      geotechnique
                    </a>
                    <a class="text-body" href="">
                      <small>Jan 01, 2045</small>
                    </a>
                  </div>

                  <Link to={`/publication/${id} `} class="m-0 ">
                    Dolor lorem eos dolor duo et eirmod sea. Dolor sit magna
                    rebum clita rebum dolor stet amet justo
                  </Link>
                </div>

                <div class="d-flex justify-content-between bg-white border border-top-0 p-4">
                  <div class="d-flex align-items-center">
                    <small>Jeremie - Ndikumagenge</small>
                  </div>
                  <div class="d-flex align-items-center"></div>
                </div>
                <div class="d-flex justify-content-between bg-white border border-top-0 p-4">
                  <Link
                    to={`/Publication/Editer/${id} `}
                    class="btn btn-outline-primary mx-3 mt-2 d-block"
                  >
                    {" "}
                    Editer
                  </Link>

                  <button
                    onClick={handleConfirm}
                    class="btn btn-outline-primary mx-3 mt-2 d-block"
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <!-- Blog End --> */}
    </>
  );
}

export default Publication;
