import React from "react";
import { Link, useLocation } from "react-router-dom";
function AdminFooter() {
  const { pathname } = useLocation();
  if (pathname !== "/connexion") {
    return (
      <>
        {/* <!-- Footer Start --> */}
        {/* <div
          class="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5"
          style={{ "border-color": "rgba(256, 256, 256, 0.1) !important" }}
        >
          <div class="row">
            <div class="col-lg-12 text-center text-md-left mb-3 mb-md-0">
              <p class="m-0 text-white">
                <div class="d-flex align-items-center">
                  &copy; <a href="#">Copyright 2023</a>.Tous droits réservés.
                  Conçu et développé par
                  <div class="mx-2 d-flex align-items-center">
                    <img
                      class="rounded-circle mr-2"
                      src="img/btr.png"
                      width="25"
                      height="25"
                      alt=""
                    />
                    Burundi en Temps Réel
                  </div>{" "}
                  and{" "}
                  <div class=" mx-2 d-flex align-items-center">
                    <img
                      class="rounded-circle mr-2"
                      src="img/dido.png"
                      width="25"
                      height="25"
                      alt=""
                    />
                    DidoYoungMan
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div> */}
        {/* <!-- Footer End --> */}

        {/* 
   <!-- Back to Top --> */}
        <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top">
          <i class="fa fa-angle-double-up"></i>
        </a>
      </>
    );
  } else {
    return null;
  }
}

export default AdminFooter;
