
function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
    return date.toLocaleDateString('fr-FR', options);
}
module.exports = { formatDate };
