import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

function EduterEquipe() {
    const { id } = useParams();
    const history = useNavigate();

    const [titre, setTitre] = useState("");
    const [selectedLabo, setSelectedLabo] = useState(null);
    const [laboList, setLaboList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Récupérer la liste des laboratoires depuis l'API
        axios
            .get("http://localhost:4000/labo")
            .then((response) => {
                setLaboList(
                    response.data.map((labo) => ({
                        value: labo.id,
                        label: labo.nom,
                    }))
                );
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, []);

    useEffect(() => {
        // Récupérer les données de l'équipe depuis l'API
        axios
            .get(`http://localhost:4000/equipe/${id}`)
            .then((response) => {
                const equipeData = response.data;
                setTitre(equipeData.titre);

                // Recherchez l'option correspondante dans la liste des laboratoires
                const laboOption = laboList.find((labo) => labo.value === equipeData.laboId);
                setSelectedLabo(laboOption);

                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error.message);
                setIsLoading(false);
            });
    }, [id, laboList]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Valider les champs ici
        if (!selectedLabo) {
            toast.error("Veuillez sélectionner un laboratoire.");
            return;
        }

        if (!titre) {
            toast.error("Veuillez remplir le champ Titre.");
            return;
        }

        setLoading(true);

        // Envoyer les données du formulaire à l'API pour enregistrement
        const formData = {
            titre,
            laboId: selectedLabo.value,
        };
        console.log(formData)
        axios
            .put(`http://localhost:4000/equipe/modify/${id}`, formData)
            .then(() => {
                toast.success("Équipe enregistrée avec succès !");
                setLoading(false);
                history("/Equipe");
            })
            .catch((error) => {
                console.error(error.message);
                setLoading(false);
                if (error.response) {
                    // Gérer les erreurs de réponse HTTP ici
                    if (error.response.status === 400) {
                        const errorMessages = error.response.data;
                        errorMessages.forEach((errorMessage) => {
                            toast.error(errorMessage);
                        });
                    } else if (error.response.status === 404) {
                        toast.error("Équipe introuvable.");
                    } else {
                        toast.error("Une erreur s'est produite lors de la modification de l'équipe.");
                    }
                } else {
                    // Erreur réseau ou autre
                    toast.error("Une erreur s'est produite lors de la modification de l'équipe.");
                }
            });
    };


    return (
        <div className="container-fluid">
            {isLoading ? (
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Chargement en cours...</span>
                    </div>
                </div>
            ) : (
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title fw-semibold mb-4">Modifier l'Équipe</h5>
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="laboId" className="form-label">
                                                Laboratoire
                                            </label>
                                            <ReactSelect
                                                id="laboId"
                                                options={laboList}
                                                value={selectedLabo}
                                                onChange={(selectedOption) => setSelectedLabo(selectedOption)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="titre" className="form-label">
                                                Titre
                                            </label>
                                            <input
                                                placeholder="Titre de l'Équipe"
                                                className="form-control"
                                                id="titre"
                                                value={titre}
                                                onChange={(e) => setTitre(e.target.value)}
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            className="btn btn-primary float-end"
                                            disabled={loading}
                                        >
                                            {loading ? <i className="fas fa-spinner fa-spin"></i> : "Enregistrer"}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default EduterEquipe;
