import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from "sweetalert2";

function ViewPost() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [Post, setPost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Missings, setMissings] = useState([]);


  function findMissingLanguages(postContents) {
    const requiredLanguages = ['fr', 'en', 'ki']; // The languages we expect to have
    const availableLanguages = postContents.map(content => content.lang); // Extract available languages
    const missingLanguages = requiredLanguages.filter(lang => !availableLanguages.includes(lang)); // Find missing languages

    return missingLanguages;
  }

  console.log(id)
  useEffect(() => {
    // Charger les données du chercheur depuis l'API
    setLoading(true)
    axios.get(`https://ishure-abarundi.webserver.control.stat-house.org/api/v2/ishure/Post/${id}`)
      .then(response => {
        const data = response.data;
        console.log(response.data)
        setPost(data);
        setMissings(findMissingLanguages(response.data.Post_Contents))
        console.log(Missings)
        setLoading(false)
      })
      .catch(error => {
        console.error(error);
      });
  }, [id]);



  function formatDate(isoDate) {
    const options = {
      weekday: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      month: "short",
      year: "numeric",
    };
    const date = new Date(isoDate);
    return date.toLocaleDateString(undefined, options);
  }

  function getLanguageName(code) {
    switch (code.toLowerCase()) {
      case 'fr':
        return 'French';
      case 'en':
        return 'English';
      case 'ki':
        return 'Kirundi';
      default:
        return 'Unknown language code';
    }
  }

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDelete = (post_content_id) => {
    Swal.fire({
      title: "Delete Post Content ?",
      text: "Are You Sure To Delete This Post Content ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      reverseButtons: true,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://ishure-abarundi.webserver.control.stat-house.org/api/v2/ishure/postcontent/delete/${post_content_id}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success("Contenue supprimé avec succès !");
              window.location.reload();
            } else {
              console.error("Erreur lors de la suppression");
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    });
  };

  return (
    <>
      {/* <!-- Blog Start --> */}
      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement en cours...</span>
          </div>
        </div>
      ) : (
        <div class="container-fluid">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <div class="position-relative overflow-hidden mt-1  mb-5 ">
                  <div class=" ">
                    <img
                      class="img-fluid w-100 d-none rounded"
                      src="../img/Structures.jfif"
                      style={{ "object-fit": "cover" }}
                    />
                  </div>
                  <div class="">
                    <div class="mb-3 d-flex justify-content-between bg-white border rounded  p-4 ">
                      <div class="d-flex align-items-center">
                        <img src="../img/video.png" alt="" className="circle-img-25" />
                        <span class="ml-3 font-weight-bold"> {Post?.post_name} </span>
                      </div>
                      <div class="d-flex align-items-center">

                        <a class="text-body" href="">
                          {formatDate(Post?.createdAt)}
                        </a>
                        <a
                          class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                          href=""
                        >

                        </a>
                        <div onClick={'logout'} class="ms-3 btn btn-primary">
                          Edit/Delete
                        </div>
                      </div>
                    </div>

                    <div class="mb-3  bg-white border rounded  p-4 ">

                      {Post?.Post_Contents.map((post) => (
                        <div class=" bg-gray-dark items mb-3   border rounded  p-4 ">
                          <div class="mb-3 d-flex justify-content-between bg-white border rounded  p-3 ">
                            <div class="d-flex align-items-center">
                              <img src="../img/video.png" alt="" className="circle-img-25" />
                              <span class="ml-3 font-weight-bold"> {getLanguageName(post?.lang)} Version </span>
                            </div>
                            <div class="d-flex align-items-center">

                              <a class="text-body bg-gray-dark border-out p-2 px-4 mr-5  text-uppercase rounded font-weight-semi-bold " href="">
                                {post?.content_type}
                              </a>
                              <a
                                class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                                href=""
                              >

                              </a>
                              <div onClick={() =>
                                alert('No Done')
                              } class="ms-3 btn btn-primary">
                                Edit
                              </div>
                              <div onClick={() =>
                                handleDelete(
                                  post?.id
                                )
                              } class="ms-3 btn btn-primary">
                                Delete
                              </div>
                            </div>
                          </div>


                          <h1 class="h3 mb-3 ms-1  font-weight-bold">
                            {post?.title}
                          </h1>


                          <div class="mb-3 d-flex justify-content-between  ">
                            <a
                              class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 mb-3"
                              href=""
                            >
                              {formatDate(post?.createdAt)}
                            </a>
                            <div class="d-flex align-items-center">
                              <div onClick={toggleDescription} class={isExpanded ? 'badge-primary text-white   border-out p-1 px-2 mr-5  text-uppercase rounded font-weight-semi-bold' : 'text-body bg-white border-out p-1 px-2 mr-5  text-uppercase rounded font-weight-semi-bold'} >
                                {isExpanded ? 'Show Less' : 'Show More'}
                              </div>
                            </div>
                          </div>

                          <p class="bg-white border rounded p-3">
                            {isExpanded ? post?.description : `${post?.description.slice(0, 100)}...`}
                          </p>

                          {post?.content_type == "text" && <div class="mb-4 border ">
                            <img
                              class="img-fluid w-100 rounded"
                              src={`https://ishure-abarundi.webserver.control.stat-house.org/uploads/photo/${post?.img_url}`}
                              style={{ "object-fit": "cover" }}
                            />
                          </div>}

                          {post?.content_type == "audio" &&

                            <div className="mb-4 ">
                              <audio
                                controls
                                className="w-full max-w-md border border-white bg-gray-dark rounded-lg shadow-md"
                              >
                                <source src={`https://ishure-abarundi.webserver.control.stat-house.org/uploads/audio/${post?.audio_url}`} type={Audio.type} />
                                Your browser does not support the audio tag.
                              </audio>
                            </div>
                          }

                          {post?.content_type == "video" &&

                            <div className="mb-4 ">


                              <iframe width="630" height="315" src={`https://www.youtube.com/embed/${post?.video_url}`} frameborder="0" allowfullscreen=""></iframe>

                            </div>
                          }


                          <p class="bg-white border rounded p-3">
          
                            <h1 class="h4 mb-3 ms-1  font-weight-bold">
                            Comments ({post?.Comments.length})
                          </h1>

                          {post?.Comments && post?.Comments.map((item, index) => {
                return (
                  <>
                   <div class="mb-3  bg-white border rounded  p-3 ">
                  <div class=" d-flex justify-content-between   p-3 ">
                            <div class="d-flex align-items-center">
                              <img src="../img/profile-.png" alt="" className="circle-img border" />
                              <span class="ml-3 font-weight-bold"> {item?.User?.name?.length > 20
                              ? item.User?.name?.slice(0, 20) + "..."
                              : item.User?.name}</span>
                            </div>
                            <div class="d-flex align-items-center">

                              <a class="text-body bg-gray-dark border-out p-2 px-4 mr-5  text-uppercase rounded font-weight-semi-bold " href="">
                                {item?.User?.state}
                              </a>
                              <a class="text-body" href="">
                          {formatDate(item?.createdAt)}
                        </a>
                            </div>
                          </div>
                          <p class="bg-white border rounded p-3">
                          {item?.comment_text}
                          </p>
                          </div>

                  </>
                );
              })}

                          </p>


                        </div>

                      ))}

                    </div>


                  </div>


                </div>



              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <div class="section-title mb-0">
                    <h4 class="m-0 text-uppercase font-weight-bold">
                      Actions
                    </h4>
                  </div>
                  <div class="bg-white border border-top-0 p-3 ">

                    {Missings?.map((Miss, index) => (

                      <div key={index}
                        class="d-flex align-items-center bg-white mb-3"
                        style={{ height: "110px" }}
                      >
                        <div class="w-100 h-100 px-3 d-flex flex-column justify-content-center rounded border  ">
                          <Link to={`/PostContent/New/${Miss}/${id}`} class="ms-3 btn btn-primary">
                            Add {getLanguageName(Miss)} Version
                          </Link>
                        </div>
                      </div>
                    ))}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


      {/* <!-- Blog End --> */}
    </>
  );
}

export default ViewPost;
